<template lang="pug">
article
  //-
  //- Hero section
  //-
  section.hero
    .hero__container
      h1.hero__title
        | Reinventing Capital Markets
        .gradient as they should be


      h2.hero__subtitle
        | The next generation european stock exchange. For great companies and great worldwide investors of all sizes.

      .hero__actions
        NuxtLink.button(
          to="https://app.portfolio.exchange"
          target="_blank"
          external) Get started

        NuxtLinkLocale.link(to="/#video")
          | Learn more
          svg(
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor")
            path(
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3")

  //-
  //- Partnes carousel
  //-
  section
    Carousel(title="Trusted by the world's most innovative teams")

  //-
  //- Authorization banner
  //-
  section
    .banner
      .banner__wrapper Portfolio Stock Exchange is a multilateral trading facility subject to European Union regulations. We are authorised by the Spanish National Stock Markets Authority (CNMV).

  //-
  //- Video section
  //-
  section(id="video")
    .section__background(style="background-image: url('/images/bck_moneym.jpg')")
      .section__header.margin-bottom-double
        .section__header__cto Everithing you need to know
        h2.section__header__title Portfolio explained in just 3 min

      .media
        .media__wrapper
          .media__video
            iframe(
              src="https://player.vimeo.com/video/716058085?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="Portfolio Stock Exchange")

  //-
  //- Responsive Capital Markets
  //-
  section
    .section__background(style="background-image: url('/images/back_responsive_n.jpg')")
      .section__header.section__header--left
        .section__header__cto Portfolio Stock Exchange
        h2.section__header__title Responsive Capital Markets
        .section__header__subtitle
          | Portfolio  is the first stock exchange in the world that offers a primary and secondary market in the same platform, integrating custody and post-trade processes for all issuers and investors, and
          span.strong.gradient.margin-left-quarter reducing costs by as much as 90%
          | .

      .section__body
        p An open, accessible Multilateral Trading Facility (MTF) for equity & debt instruments, participations in investment funds and money market instruments.

        p
          | For Real Estate.
          br
          | For Mid Cap Co's & Tech startups.
          br
          | For FMCG, Industrial & Retail companies.
          br
          | For Family businesses.
          br
          | For Project Finance.
          br
          | For eSports.
          br
          | For Equity & Debt crowdfunding platforms.
          br
          | For Bonds.
          br
          | For Private Equity & VCs.
          br
          | For Alternative Investment Funds.
          br
          | For other investment vehicles.

        p.strong.gradient Portfolio, the stock exchange for ambitious companies of any size, wherever they are based.

  //-
  //- New stock exchange, New possibilities
  //-
  section.margin-bottom-quadruple
    Parallax(background="/images/bck_row9.jpg")
      h2
        | New stock exchange,
        br
        | New possibilities

  //-
  //- Portfolio's secret sauce
  //-
  section
    .section__header.section__header--left
      .section__header__cto Traditional stock exchanges' business model is broken.
      h2.section__header__title Portolio's secret sauce
      .section__header__subtitle Our technical and legal innovations allow us to be the first stock exchange that prioritizes client requirements for access, for security, for privacy, and for control, all while streamlining processes to deliver unprecedented savings in time and costs.

    .section__body
      p Portfolio is a redesigned end-to-end venue for equity, bonds & funds with a streamlined issuance process and the management of the whole lifecycle of your financial instrument.
      p
        span.strong.gradient.margin-right-quarter We remove 14 traditional middlemen saving up to 90% of the overall cost
        | and giving access to investors of all sizes, even retail.

  Infographic.padding-top-quadruple.padding-bottom-quadruple

  //-
  //- Direct access to investors of all sizes
  //-
  section.margin-bottom-quadruple
    Parallax(background="/images/bck_row2.jpg")
      h2
        | Direct access to investors
        br
        | of all sizes

  //-
  //- 12+1 Portfolio's main advantages
  //-
  section.margin-bottom-quadruple
    .section__header
      .section__header__cto Portfolio Stock Exchange
      h2.section__header__title 12+1 Portfolio's main advantages
      .section__header__subtitle Here you have a brief overview about the benefits you can have with Portfolio.

    AdvantagesTable

  //-
  //- Let's bring the digital era to capital markets
  //-
  section.margin-bottom-quadruple
    Parallax(background="/images/bck_row1.jpg")
      h2
        | Let's bring the digital era
        br
        | to capital markets

  //-
  //- Portfolio's features
  //-
  section.margin-bottom-quadruple
    .features
      dl.features__list
        .feature
          dt
            .feature__icon
              img(
                src="/icons/ico_funding.svg"
                alt="Funding")
            | Funding
          dd Find funding via an IPO, debt issuance or merging with a SPAC.

        .feature
          dt
            .feature__icon
              img(
                src="/icons/ico_target.svg"
                alt="Target investors")
            | Target investors
          dd Target institutional, professional or retail investors directly through our platform or digital marketing.

        .feature
          dt
            .feature__icon
              img(
                src="/icons/ico_tailor.svg"
                alt="Tailor-made listings")
            | Tailor-made listings
          dd At Portfolio, we think that Capital Markets are yet to be disrupted, so we aim to do it by letting our clients build that future.

        .feature
          dt
            .feature__icon
              img(
                src="/icons/ico_syndicate.svg"
                alt="Syndication made easy")
            | Syndication made easy
          dd Syndicate all your investors digitally with a simple click.

        .feature
          dt
            .feature__icon
              img(
                src="/icons/ico_engange.svg"
                alt="Engage your clients")
            | Engage your clients
          dd Allow your client or fan base to invest in your company directly so they can participate in your growth.

        .feature
          dt
            .feature__icon
              img(
                src="/icons/ico_restriction.svg"
                alt="Restrictions to shareholders")
            | Restrictions to shareholders
          dd Introduce trading pre-approval requirements or set restrictions to voting rights.

        .feature
          dt
            .feature__icon
              img(
                src="/icons/ico_onboarding.svg"
                alt="Digital onboarding")
            | Digital onboarding
          dd Let your investors benefit from a streamlined, customized digital onboarding experience.

        .feature
          dt
            .feature__icon
              img(
                src="/icons/ico_save.svg"
                alt="Save time & money")
            | Save time & money
          dd Accomplish your issuance process faster, saving up to 90% of the overall costs.

  //-
  //- A stock exchange should be an enabler, not an obstacle
  //-
  section.margin-bottom-double
    Parallax(background="/images/bck_row3.jpg")
      h2
        | A stock exchange should be an enabler,
        br
        | not an obstacle

  //-
  //- Investors section
  //-
  section
    .section__background(style="background-image: url('/images/back_investors_n.jpg')")
      .section__header.section__header--left
        .section__header__cto Portfolio Stock Exchange
        h2.section__header__title Investors
        .section__header__subtitle Portfolio allows every ECP, professional investor, financial advisor, family office and even retail investor to operate on their own, directly through our web platform and mobile apps.

      .section__body
        h4 Access to the market
        p Directly through our platform. No member or broker is needed.

        h4 KYC/KYB + Securities account
        p Be ready to invest in a few minutes with our smooth digital onboarding process, both for companies and individuals. We instantly open your securities account and you are ready to invest right away.

        h4 Custody
        p Avoid extra cost. We safekeep your funds and financial instruments.

        .layout--center
          NuxtLinkLocale.button(to="/portfolio-investors") Learn more
</template>

<script setup lang="ts">
definePageMeta({
  title: 'pages.home.title',
  description: 'pages.home.description'
})

useHead({
  script: [{
    src: "https://player.vimeo.com/api/player.js"
  }]
});
</script>
